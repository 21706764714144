import { defineStore } from "pinia"
import { BulkImportData, Lead } from "@/shared/models/lead"
import { LeadService } from "@/shared/services/lead"
import { TableState } from "@/shared/models/table"
import { Tag } from "@/shared/models/tag"
import { ToastModel } from "@/shared/models/toast-model"
import { useTimelineStore } from "@/stores/timeline"
import { ToastService } from "@/shared/services/toast"
import { EditableAddressPayload, EditableEmail, EditablePhonePayload } from "@/shared/models/contact-information"
import { GgmsError } from "@/shared/services/client"
import { useGridCollectionStore } from "@/stores/grid-collection"
import { useSocketStore } from "@/stores/socket"
import { ACL } from "@/shared/models/role"

export const useLeadsStore = defineStore("leads", {
    state: () => ({
        tableState: {
            data: [],
            selectedData: [],
            currentPage: 0,
            lastPage: 0,
            pageLength: 50,
            numberOfPages: 0,
            total: 0,
            isAllSelected: false,
            isLoading: false,
            assignedAgentsACLs: [],
            firstIds: [],
        } as TableState<Lead>,
        leadService: new LeadService(),
        toastService: new ToastService(),
        lead: {} as Lead,
        timelineStore: useTimelineStore(),
        isLoading: false,
        gridCollectionStore: useGridCollectionStore(),
        socketStore: useSocketStore(),
        isAddLeadModalOpen: false,
        isError: false,
    }),
    actions: {
        async loadLeads() {
            try {
                this.tableState.isLoading = true

                let lastId = null
                let offset = null

                // If we're going to the next page, use keyset pagination.
                if (this.tableState.currentPage === (this.tableState?.lastPage ?? 0) + 1) {
                    lastId =
                        this.tableState.data.length > 0
                            ? this.tableState.data[this.tableState.data?.length - 1]._id
                            : null
                }
                // If we're jumping to a specific page, use offset-based pagination.
                else if (
                    this.tableState.currentPage !== (this.tableState?.lastPage ?? 0) + 1 &&
                    this.tableState.currentPage > 1
                ) {
                    offset = (this.tableState.currentPage - 1) * this.tableState.pageLength
                }

                const filtersGroups = this.gridCollectionStore?.grid?.filtersGroups?.length
                    ? encodeURIComponent(JSON.stringify(this.gridCollectionStore.grid.filtersGroups))
                    : undefined

                const response = await this.leadService.getAll({
                    length: this.tableState.pageLength,
                    column: this.tableState.column,
                    order: this.tableState.order,
                    search: this.tableState.search,
                    filtersGroups,
                    lastId,
                    offset,
                })

                // Store the first ID of the new page.
                if (this.tableState.firstIds && this.tableState.currentPage) {
                    this.tableState.firstIds[this.tableState.currentPage - 1] = response.data[0]?._id
                }

                this.tableState.data = response.data
                this.tableState.total = response.total
                this.tableState.selectedData = []
                this.tableState.numberOfPages = Math.ceil(response.total / this.tableState.pageLength)
                this.tableState.lastPage = this.tableState.currentPage
            } finally {
                this.tableState.isLoading = false
            }
        },

        resetTableState() {
            this.tableState.data = []
            this.tableState.total = 0
            this.tableState.selectedData = []
            this.tableState.numberOfPages = Math.ceil(1)
        },

        async getLeads(searchString: string) {
            return this.leadService.searchLeads(searchString, undefined, undefined)
        },

        async getLeadsGrid() {
            try {
                this.tableState.isLoading = true
                const response = await this.gridCollectionStore.getGrid("leads")
                const createdAtFilter = response.data[0].filtersGroups
                    .flatMap((group) => group.filters)
                    .find((filter) => filter.field === "createdAt" && filter.rule === "isBetween")

                if (!createdAtFilter) {
                    await this.loadLeads()
                }
                return response
            } finally {
                this.tableState.isLoading = false
            }
        },

        async getAssignedAgentsACLs(ids: string[]) {
            try {
                if (!ids) {
                    return
                }
                this.tableState.isLoading = true
                this.tableState.ids = ids?.join(",") ?? ""
                const response = await this.leadService.getAssignedAgentsACLs(this.tableState.ids)
                this.tableState.assignedAgentsACLs = response
                return response
            } finally {
                this.tableState.isLoading = false
            }
        },

        async updateLeadsGrid() {
            try {
                this.tableState.isLoading = true
                if (!this.gridCollectionStore.grid) return
                this.gridCollectionStore.grid.filtersGroups = this.gridCollectionStore.filtersGroups || []

                const response = await this.gridCollectionStore.updateGrid("leads", this.gridCollectionStore.grid)
                this.gridCollectionStore.gridResponse = response
                this.gridCollectionStore.grid = this.gridCollectionStore.gridResponse.grid
                this.gridCollectionStore.filtersGroups = this.gridCollectionStore.grid.filtersGroups

                await this.loadLeads()
            } finally {
                this.tableState.isLoading = false
            }
        },

        async clearFilters() {
            this.gridCollectionStore.grid.filtersGroups = []
            this.gridCollectionStore.filtersGroups = []

            this.updateLeadsGrid()
        },

        addLead(data: Partial<Lead>) {
            return this.leadService.addLead(data)
        },

        async updateLead(id: string, data: Partial<Lead>, toastMessage?: string) {
            try {
                const response = await this.leadService.updateLead(id, data)
                if (!response) {
                    return
                }
                this.lead = response.lead

                if (toastMessage) {
                    this.toastService.addToast({
                        type: "success",
                        message: toastMessage,
                    })
                }

                return response
            } catch (error) {
                const err = error as GgmsError
                this.isError = true
                if (err.code === "ValidationError") {
                    this.toastService.addToast({
                        type: "error",
                        message: err.fullMessage ?? err.message,
                    })
                }
            }
        },

        async getOne(leadId: string) {
            try {
                this.timelineStore.isTimelineLoading = true
                const response = await this.leadService.getOne(leadId)
                if (!response) return

                this.lead = {
                    ...response.lead,
                    isUnknownContact: false,
                }

                if (response.lead?.timelineItems) {
                    this.timelineStore.timelineItems = response.lead.timelineItems.data
                    this.timelineStore.totalTimelineItems = response.lead.timelineItems.total
                    this.timelineStore.pinnedTimelineItem = this.timelineStore.getPinnedTimelineItem()
                }
            } finally {
                this.timelineStore.isTimelineLoading = false
            }
        },

        bulkImport(payload: BulkImportData) {
            return this.leadService.bulkImport(payload)
        },

        async bulkUpdateTagOnLead(leadIds: string[], toAdd: Tag[], toRemove: Tag[], all: boolean) {
            const { job } = await this.leadService.bulkUpdateTagOnLead(leadIds, toAdd, toRemove, all)
            this.socketStore.addJob(job)
            this.lead.tags = this.lead?.tags?.concat(toAdd)
            this.lead.tags = this.lead?.tags?.filter((tag) => !toRemove.find((t) => t._id === tag._id))
        },

        async bulkUpdatePermissionsOnLead(
            leadIds: string[],
            toAdd: ACL[],
            toRemove: string[],
            listsToAdd: ACL[],
            listsToRemove: string[],
            all: boolean
        ) {
            await this.leadService.bulkUpdatePermissionsOnLead(leadIds, toAdd, toRemove, listsToAdd, listsToRemove, all)
        },

        async searchLeads(search: string, hasPhone: undefined | boolean, limit: undefined | number) {
            try {
                this.isLoading = true
                const leads = await this.leadService.searchLeads(search, hasPhone, limit)
                return leads
            } finally {
                this.isLoading = false
            }
        },

        async updateLeadsEmail(leadId: string, emails: EditableEmail[]) {
            try {
                const res = await this.leadService.updateLeadsEmail(leadId, emails)
                if (res) {
                    this.toastService.addToast({
                        type: "success",
                        message: "Email(s) updated successfully",
                    })
                    return res
                }
            } catch (error) {
                const err = error as GgmsError
                if (err.code === "ValidationError") {
                    this.toastService.addToast({
                        type: "error",
                        message: err.message,
                    })
                }
            }
        },

        async updateLeadsPhone(leadId: string, phones: EditablePhonePayload[]) {
            try {
                const res = await this.leadService.updateLeadsPhone(leadId, phones)
                if (res) {
                    this.toastService.addToast({
                        type: "success",
                        message: "Phone(s) updated successfully",
                    })
                    return res
                }
            } catch (error) {
                const err = error as GgmsError
                if (err.code === "ValidationError") {
                    this.toastService.addToast({
                        type: "error",
                        message: err.message,
                    })
                }
            }
        },

        async updateLeadsAddress(leadId: string, addresses: EditableAddressPayload[]) {
            try {
                const res = await this.leadService.updateLeadsAddress(leadId, addresses)
                if (res) {
                    this.toastService.addToast({
                        type: "success",
                        message: "Address(es) updated successfully",
                    })
                    return res
                }
            } catch (error) {
                const err = error as GgmsError
                if (err.code === "ValidationError") {
                    this.toastService.addToast({
                        type: "error",
                        message: err.message,
                    })
                }
            }
        },

        async updateStage(stage: Tag) {
            await this.leadService.updateStage(this.lead._id, stage)
            this.lead.stage = stage
        },

        async shareLead(leadId: string, agentsEmails: string[], permissionType: string) {
            try {
                const res = await this.leadService.shareLead(leadId, agentsEmails, permissionType)
                if (res) {
                    this.toastService.addToast({
                        type: "success",
                        message: "Lead shared successfully",
                    })
                    return res
                }
            } catch (error) {
                const err = error as GgmsError
                if (err.code === "ValidationError") {
                    this.toastService.addToast({
                        type: "error",
                        message: err.message,
                    })
                }
            }
        },

        clearLead() {
            this.lead = {} as Lead
        },

        async unsubscribeLead(token: string, leadId: string) {
            try {
                await this.leadService.unsubscribeLead(token, leadId)
            } catch (error) {
                const err = error as GgmsError
                if (err.code === "ValidationError") {
                    this.toastService.addToast({
                        type: "error",
                        message: err.message,
                    })
                }
            }
        },

        async checkLeadList(typename: string, leadIds: string[]) {
            try {
                return this.leadService.checkLeadList(typename, leadIds)
            } catch (error) {
                const err = error as GgmsError
                if (err.code === "ValidationError") {
                    this.toastService.addToast({
                        type: "error",
                        message: err.message,
                    })
                }
            }
        },
    },
})
